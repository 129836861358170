import React from 'react';
import Image from './Image';
import { toTitleCase } from '../../utilities/String';

const Container = props => (
    <div className="focused-card-wrapper">
        <Image card={props.card} />
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{toTitleCase(props.card.name)}</h5>
                <div className="card-text">
                    <div>
                        <h6>Fortune</h6>
                        {props.renderList(props.card.fortune_telling)}
                    </div>
                </div>
                <div className="card-data">
                    {props.renderBadges(props.card.keywords)}
                </div>
            </div>
        </div>
    </div>
);

export default Container;